.map-outer {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
  position: relative;
}

.map-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.transparentbg {

  background: linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white;
  background-repeat: repeat, repeat;
  background-position: 0px 0, 5px 5px;
  transform-origin: 0 0 0;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  background-size: 10px 10px, 10px 10px;
  box-shadow: none;
  text-shadow: none;
  transition: none;
  transform: scaleX(1) scaleY(1) scaleZ(1);
}
